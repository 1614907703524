import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../modules/auth';
import { ValidTokenResponse } from '../modules/auth/components/models/auth.token.valid.response.model';
import { OnboardingStatus } from '../modules/onboarding/model/onboarding.model';

@Injectable({
    providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {


    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | booleano {
        const wrapperSubject = new Subject<boolean>();
        this.authService.isValidToken().subscribe((res : ValidTokenResponse) => {
            if(res?.isValid) {
                if(res?.onboardingStatus == null || res?.onboardingStatus == OnboardingStatus.PENDING_STATUS || res?.onboardingStatus == OnboardingStatus.CONFIGURED_STATUS || res?.onboardingStatus == OnboardingStatus.NOT_CONFIGURED_STATUS) return wrapperSubject.next(true);
                return wrapperSubject.next(false);
            }
            else{
                this.authService.clearCookiesAndLocalStorage();
            }
            return wrapperSubject.next(false);
        }, (err : any)=> {
            this.authService.clearCookiesAndLocalStorage();
            return wrapperSubject.next(false);
        });
        return wrapperSubject;
    }

}
</boolean></boolean>