// Angular
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
// RxJS
import { Observable, Subject, BehaviorSubject, of, empty, throwError } from 'rxjs';
import { tap, switchMap, filter, take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthResponse } from '../components/models/auth.login.response.model';
import { AuthService } from '..';
import { environment } from 'src/environments/environment';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router,private cookieService: CookieService) {}
  
  private excludeInterceptors : string[] = [
    "https://warmi-payment-stg.s3.amazonaws.com",
    "https://warmi-payment-massive-process-dev.s3.amazonaws.com",
    "https://warmi-payment-web-form-dev.s3.amazonaws.com",
    "https://warmi-payment-company-extranet-dev.s3.amazonaws.com",
    "https://moonflow-public-stg.s3.amazonaws.com",
    "https://moonflow-prd.s3.amazonaws.com",
    "https://moonflow-massive-process-prd.s3.amazonaws.com",
    "https://moonflow-web-form-prd.s3.amazonaws.com",
    "https://moonflow-company-extranet-prd.s3.amazonaws.com",
    "https://warmi-payment-public-stg.s3.amazonaws.com",
    "https://moonflow-public-prd.s3.amazonaws.com",
    "https://s3.amazonaws.com/notifications.khipu.com"
  ];

  private onlyCloneRequest : string[] = [
    "https://warmi-payment-stg.s3.amazonaws.com",
    "https://warmi-payment-massive-process-dev.s3.amazonaws.com",
    "https://warmi-payment-web-form-dev.s3.amazonaws.com",
    "https://warmi-payment-company-extranet-dev.s3.amazonaws.com",
    "https://moonflow-public-stg.s3.amazonaws.com",
    "https://moonflow-prd.s3.amazonaws.com",
    "https://moonflow-massive-process-prd.s3.amazonaws.com",
    "https://moonflow-web-form-prd.s3.amazonaws.com",
    "https://moonflow-company-extranet-prd.s3.amazonaws.com",
    "https://warmi-payment-public-stg.s3.amazonaws.com",
    "https://www.googleapis.com/webfonts/v1/webfonts",
    "https://moonflow-public-prd.s3.amazonaws.com",
    "https://s3.amazonaws.com/notifications.khipu.com"
  ];

  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(nulo);

  interceptar(request: HttpRequest<any>, next: HttpHandler): Observável<httpevent<any>> {
    const token = localStorage.getItem(environment.authTokenKey);
     if (!token || request.url.indexOf('/auth/refresh') > -1) {
      return next.handle(
        request.clone({
          withCredentials: true,
        }),
      );
    } 

    if (!this.authService.isTokenExpired()) {
      return next.handle(this.injectToken(request)).pipe(
        catchError((error: any) => {
          if (error.status == 401) {
            this.router.navigate(['/auth/login'], {
              queryParams: {},
            });
          }
          if (error.status == 403) {
            this.router.navigate(['/my-account/my-profile'], {
              queryParams: {},
            });
            throw new Error("FORBIDDEN");
          }
          return throwError(error)
        })
      );
    } else {
      if (!this.refreshTokenInProgress) {
        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authService.refresh().pipe(
          catchError((e : any) => {
            this.authService.clearCookiesAndLocalStorage();
            this.refreshTokenInProgress = false;
            this.router.navigateByUrl('/auth/login');
            return empty();
          }),
          switchMap((authResponse : AuthResponse) => {
            if (!authResponse?.token || !authResponse) {
              this.refreshTokenInProgress = false;
              this.authService.clearCookiesAndLocalStorage();
              this.router.navigateByUrl('/auth/login');
              return empty();
            }

            localStorage.setItem(environment.authTokenKey, authResponse.token);
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(authResponse.token);
            return next.handle(this.injectToken(request));
          }),
        );
      } else {
        return this.refreshTokenSubject.pipe(
          filter((result) => result !== null),
          take(1),
          switchMap((res) => {
            return next.handle(this.injectToken(request));
          }),
        );
      }
    }
  }

  private injectToken(request: HttpRequest<any>) {
    const token = localStorage.getItem(environment.authTokenKey);
    if(this.onlyCloneRequest.find(x => request.url.includes(x))){
      return request.clone();
    }
    if(this.excludeInterceptors.find(x => request.url.includes(x))){
      return request.clone({
        setHeaders: {
          Timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
        },
      });
    }
    return request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
      },
    });
  }
}
</any></httpevent<any></any></any></any>