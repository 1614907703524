import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<authmodel>(`${API_USERS_URL}/login`, {
      email,
      password,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<usermodel> {
    return this.http.post<usermodel>(API_USERS_URL, usuário);
  }

  // Seu servidor deve verificar o e-mail => Se o e-mail existir, envie o link para o usuário e retorne true | Se o e-mail não existir, retorne false
  forgotPassword(email: string): Observável<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<usermodel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<usermodel>(`${API_USERS_URL}/me`, {
      headers: httpHeaders,
    });
  }
}
</usermodel></usermodel></boolean></boolean></usermodel></usermodel></authmodel></any>